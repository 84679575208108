import { createAction, createReducer, Reducer } from '@reduxjs/toolkit'
import { Milestone } from '../../models/Dashboard'
import { ProjectMilestone } from '../../types/projectMilestone'

const initialState: ProjectMilestone = {
  milestones: [],
  selectedMilestone: undefined,
}

export const setMilestoneList = createAction<Milestone[]>('SET_MILESTONES')
export const setMilestoneData = createAction<Milestone>('SET_MILESTONE')

type ProjectMilestoneAction = ReturnType<typeof setMilestoneList> | ReturnType<typeof setMilestoneData>

const ProjectMilestoneReducer: Reducer<ProjectMilestone, ProjectMilestoneAction> = createReducer(
  initialState,
  (builder) => {
    builder.addCase(setMilestoneList, (state, action) => {
      state.milestones = action.payload
    })

    builder.addCase(setMilestoneData, (state, action) => {
      state.selectedMilestone = action.payload
    })
  },
)

export default ProjectMilestoneReducer
