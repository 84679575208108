import { createAction, createReducer, Reducer } from '@reduxjs/toolkit'

export type ModalState = {
  clientLoginModal: boolean
  clientMoveBranch: string
  clientChangePasswordModal: boolean
  clientWithdrawalModal: boolean
  loadingModal: boolean
  loginCompleteModal: boolean
  menuModal: boolean
  solutionMakeCompleteModal: boolean
  portfolioModal: boolean
  portfolioModalSelectedId: string
  consultingTimeReservationModal: boolean
}

const initialState: ModalState = {
  clientLoginModal: false,
  clientMoveBranch: '/',
  clientChangePasswordModal: false,
  clientWithdrawalModal: false,
  loadingModal: false,
  loginCompleteModal: false,
  menuModal: false,
  solutionMakeCompleteModal: false,
  portfolioModal: false,
  portfolioModalSelectedId: '',
  consultingTimeReservationModal: false,
}

export const clientLoginModalAction = createAction<boolean>('CLIENT_LOGIN')
export const clientMoveBranchAction = createAction<string>('CLIENT_LOGIN_MOVE_BRANCH')
export const clientChangePasswordModalAction = createAction<boolean>('CLIENT_CHANGE_PASSWORD')
export const clientWithdrawalModalAction = createAction<boolean>('CLIENT_WITHDRAWAL')
export const loadingModalAction = createAction<boolean>('LOADING_MODAL')
export const loginCompleteModalAction = createAction<boolean>('LOGIN_COMPLETE_MODAL')
export const menuModalAction = createAction<boolean>('MENU_MODAL')
export const solutionMakeCompleteModalAction = createAction<boolean>('SOLUTION_MAKE_COMPLETE')
export const portfolioModalAction = createAction<boolean>('PORTFOLIO_MODAL')
export const portfolioModalSelectedIdAction = createAction<string>('PORTFOLIO_MODAL_SELECTED_ID')
export const consultingTimeReservationModalAction = createAction<boolean>('CONSULTING_TIME_RESERVATION_MODAL')

type ModalActions =
  | ReturnType<typeof clientLoginModalAction>
  | ReturnType<typeof clientMoveBranchAction>
  | ReturnType<typeof clientChangePasswordModalAction>
  | ReturnType<typeof loadingModalAction>
  | ReturnType<typeof loginCompleteModalAction>
  | ReturnType<typeof menuModalAction>
  | ReturnType<typeof solutionMakeCompleteModalAction>
  | ReturnType<typeof portfolioModalAction>
  | ReturnType<typeof portfolioModalSelectedIdAction>
  | ReturnType<typeof consultingTimeReservationModalAction>

const modalReducer: Reducer<ModalState, ModalActions> = createReducer(initialState, (builder) => {
  builder.addCase(clientLoginModalAction, (state, action) => {
    state.clientLoginModal = action.payload
  })

  builder.addCase(clientMoveBranchAction, (state, action) => {
    state.clientMoveBranch = action.payload
  })

  builder.addCase(clientChangePasswordModalAction, (state, action) => {
    state.clientChangePasswordModal = action.payload
  })

  builder.addCase(clientWithdrawalModalAction, (state, action) => {
    state.clientWithdrawalModal = action.payload
  })

  builder.addCase(loadingModalAction, (state, action) => {
    state.loadingModal = action.payload
  })

  builder.addCase(loginCompleteModalAction, (state, action) => {
    state.loginCompleteModal = action.payload
  })

  builder.addCase(menuModalAction, (state, action) => {
    state.menuModal = action.payload
  })

  builder.addCase(solutionMakeCompleteModalAction, (state, action) => {
    state.solutionMakeCompleteModal = action.payload
  })

  builder.addCase(portfolioModalAction, (state, action) => {
    state.portfolioModal = action.payload
  })

  builder.addCase(portfolioModalSelectedIdAction, (state, action) => {
    state.portfolioModalSelectedId = action.payload
  })

  builder.addCase(consultingTimeReservationModalAction, (state, action) => {
    state.consultingTimeReservationModal = action.payload
  })
})

export default modalReducer
