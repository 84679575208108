import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import RootReducer from './store/reducers'
import 'react-app-polyfill/stable'
import 'bootstrap/dist/css/bootstrap.css'
import { GlobalStyle } from './components/default/styled'
import './services/dayjs'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

const store = configureStore({
  reducer: RootReducer,
  devTools: true,
})

// 🔹 환경 변수로 Sentry DSN 설정
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || ''

console.log('SENTRY_DSN', SENTRY_DSN)

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api-*.gridge\.co.kr/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_APP_ENV,
})

const container = document.getElementById('app')

const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
)
