import { combineReducers, Reducer } from '@reduxjs/toolkit'
import ModalReducer, { ModalState } from './modal'
import UTMReducer from './utm'
import ProjectMilestoneReducer from './projectMilestone'
import LoginReducer, { LoginState } from './login'
import { ProjectMilestone } from '../../types/projectMilestone'
import { Utm } from '../../types/utm'

export type RootState = {
  login: LoginState
  modal: ModalState
  projectMilestone: ProjectMilestone
  utm: Utm
}

const RootReducer: Reducer<RootState> = combineReducers({
  login: LoginReducer,
  modal: ModalReducer,
  projectMilestone: ProjectMilestoneReducer,
  utm: UTMReducer,
})

export default RootReducer
